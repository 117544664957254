import React from "react"
import { graphql } from "gatsby"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import MainToolbar from "../components/main-toolbar"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"
import LineTitle from "../components/LineTitle"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  bannerGrid: {
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "0px",
      height: "42vh",
    },
  },
  bannerLeft: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  mainContainer: {
    padding: "0 !important",
    paddingBottom: "120px",
    backgroundColor: "#202020",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "60px",
    },
  },
  tooLongtitle: {
    color: "white",
    maxWidth: 200,
    ["@media (min-width:1440px)"]: {
      fontSize: "5rem",
    },
    ["@media (max-width:600px)"]: {
      fontSize: "2rem",
    },
  },
  tooLongSubheader: {
    color: "silver",
    // backgroundColor: "green",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    maxWidth: "650px",
    ["@media (max-width:600px)"]: {
      fontSize: "1.3rem",
      // maxWidth: "600px",
    },
  },
  introHeader: {
    textAlign: "left",
    paddingTop: 0,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 360,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
      paddingTop: 40,
    },
  },
  exampleHeader: {
    textAlign: "center",
    paddingTop: 0,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 360,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
      paddingTop: 40,
    },
  },
  bodyText: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 20,
  },
  figText: {
    fontSize: "1.1rem",
    textAlign: "left",
    fontStyle: "italic",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
  },
  backgroundImg: {
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "right",
      height: "280px",
    },
  },
  introContainer: {
    paddingTop: 120,
    paddingBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  spacer: {
    height: 40,
  },
  aspectsSection: {
    backgroundColor: "#080808",
    width: "100%",
    padding: "80px 0",
    margin: 0,
  },
  aspectsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: 0,
    margin: "0 auto",
    maxWidth: "1200px",
  },
  aspectItem: {
    width: "100%",
    maxWidth: "800px",
    padding: "40px 0",
    textWrap: "balance",
  },
  aspectHeader: {
    color: "white",
    fontWeight: 300,
    marginBottom: "20px",
  },
  aspectBodyText: {
    fontSize: "1.1rem",
    color: "white",
    whiteSpace: "pre-line",
    margin: 0,
    textWrap: "balance",
  },
  imageSection: {
    backgroundColor: "#202020",
    width: "100%",
    padding: "80px 0",
    margin: 0,
  },
  imageContainer: {
    maxWidth: "100%",
    width: "100%",
    maxWidth: "800px",
    padding: 0,
    margin: "0 auto",
    marginBottom: "60px",
  },
  imageItem: {
    marginBottom: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "80px",
  },
  image: {
    width: "100%",
    maxWidth: "1400px",
    height: "auto",
  },
  imageCaption: {
    marginTop: "10px",
    fontSize: "1rem",
    color: "white",
  },
  exampleContainer: {
    paddingTop: 60,
    paddingBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  exampleTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 0",
    textAlign: "center",
  },
}))

const MaleFacelift = ({ intl, data, location }) => {
  const classes = useStyles()
  const t = (id) => intl.formatMessage({ id })
  const banner = data.bannerImage.childImageSharp.fluid

  const exampleImages = [
    {
      src: data.maleFaceliftFront.childImageSharp.fluid,
      alt: "Before and after male facelift front example",
      caption:
        "Frontal view of patient that under went Exteneded Deep Plane Facelift Deep Neck Lift Fat grafting and Brow Lift",
    },
    {
      src: data.maleFaceliftLateral.childImageSharp.fluid,
      alt: "Before and after male facelift example 2",
      caption:
        "The dramatic changes observed in the lateral view highlight both facial rejuvenation and enhanced masculinization. The brow now rests in a higher, more natural position, contributing to a relaxed and youthful appearance with a noticeable reduction in forehead wrinkles. The panfacial fat grafting has significantly improved skin quality, smoothing out fine wrinkles across the face and creating a revitalized complexion. Additionally, the jawline exhibits a more rugged and powerful contour, a characteristic high- testosterone feature that enhances the masculine aesthetic. Overall, these refinements result in a striking transformation that combinesrejuvenation with a strong, masculine appeal.",
    },
    {
      src: data.maleFacelift34.childImageSharp.fluid,
      alt: "Before and after male facelift example 3",
      caption:
        "The ¾ view highlights the remarkable improvement in the midface, achieved through a combination of fat grafting and an extended deep plane facelift. This dual approach has effectively rejuvenated the midface, restoring volume and creating a natural, youthful contour. The jawline and shadow line have been meticulously reconstructed, with the mandibular angle now regaining its youthful prominence. These enhancements create a balanced and refined appearance, showcasing both rejuvenation and structural restoration and a masculine aesthetic.",
    },
  ]

  return (
    <Layout>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "maleFacelift.seoTitle" })}
        description={intl.formatMessage({ id: "maleFacelift.seoDescription" })}
        path={location.pathname}
      />
      <MainToolbar black />
      <Container maxWidth={"xl"} className={classes.mainContainer}>
        <section>
          <BackgroundImage
            fluid={banner}
            className={classes.backgroundImg}
            backgroundColor={`#333`}
          >
            <Grid container spacing={0} className={classes.bannerGrid}>
              <Grid item xs={12} sm={4} className={classes.bannerLeft}>
                <Container>
                  <LineTitle
                    header={t("maleFacelift.title")}
                    subheader={t("maleFacelift.subtitle")}
                    titleStyle={classes.tooLongtitle}
                    lightDecoLine
                    subHeaderStyle={classes.tooLongSubheader}
                  />
                </Container>
              </Grid>
              <Grid item xs={12} sm={8}></Grid>
            </Grid>
          </BackgroundImage>
          <div className={classes.spacer} />
          <Grid container spacing={12} className={classes.introContainer}>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h2"
                variant="h3"
                className={classes.introHeader}
              >
                {t("maleFacelift.introTitle")}
              </Typography>
              <p className={classes.bodyText}>{t("maleFacelift.introText")}</p>
            </Grid>
            <Grid item xs={12} sm={6} align="center">
              <Img
                fluid={data.maleProfile.childImageSharp.fluid}
                alt="before and after deep plane facelift example"
              />
            </Grid>
          </Grid>
          <Grid container spacing={12} className={classes.exampleContainer}>
            <Grid
              item
              xs={12}
              sm={6}
              align="center"
              className={classes.exampleTitle}
            >
              <Typography
                component="h2"
                variant="h3"
                className={classes.exampleHeader}
              >
                Example facelift
              </Typography>
              {/* <p className={classes.bodyText}>{t("maleFacelift.introText")}</p> */}
            </Grid>
            <Grid item xs={12} sm={6} align="center">
              <Img
                fluid={data.maleFaceliftLateral.childImageSharp.fluid}
                alt="before and after deep plane facelift example"
              />
            </Grid>
          </Grid>
        </section>
        <section className={classes.aspectsSection}>
          <div className={classes.aspectsContainer}>
            {Array.from({ length: 6 }, (_, index) => {
              const title = t(`maleFacelift.aspects.title${index + 1}`)
              const text = t(`maleFacelift.aspects.text${index + 1}`)

              return (
                <div key={index} className={classes.aspectItem}>
                  <Typography
                    variant="h4"
                    component="h3"
                    className={classes.aspectHeader}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.aspectBodyText}
                  >
                    {text}
                  </Typography>
                </div>
              )
            })}
            <div className={classes.aspectItem}>
              <Typography
                variant="h4"
                component="h3"
                className={classes.aspectHeader}
              >
                {t("maleFacelift.bottomLineTitle")}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.aspectBodyText}
              >
                {t("maleFacelift.bottomLineText")}
              </Typography>
              {/* <button className={classes.bookButton}>
                {t("maleFacelift.bottomLine.button")}
              </button> */}
            </div>
          </div>
        </section>
        <section className={classes.imageSection}>
          <div className={classes.imageContainer}>
            {exampleImages.map((image, index) => (
              <div key={index} className={classes.imageItem}>
                <Img
                  fluid={image.src}
                  alt={image.alt}
                  className={classes.image}
                />
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.imageCaption}
                >
                  {t(`maleFacelift.imageCaptions.caption${index + 1}`)}
                </Typography>
              </div>
            ))}
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default injectIntl(MaleFacelift)

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "heros/male-facelift-banner.webp" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    maleProfile: file(relativePath: { eq: "male-jawline.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    maleFaceliftFront: file(
      relativePath: { eq: "male-facelift-front-before-after.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    maleFaceliftLateral: file(
      relativePath: { eq: "male-facelift-example-lateral-2.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    maleFacelift34: file(
      relativePath: { eq: "male-facelift-example-34.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
